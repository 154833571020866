import {
  AppstoreOutlined,
  DollarOutlined,
  RocketOutlined,
  SettingOutlined,
  TeamOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

const manage = {
  key: "category-manage",
  icon: null,
  label: "MANAGE",
  type: "group",
  children: [
    {
      key: "/dashboard/financial",
      label: "Financial Insights",
      icon: <DollarOutlined />,
      children: [
        {
          key: "/dashboard/financial/earning",
          label: "Earnings",
          icon: null,
        },
      ],
    },
    {
      key: "/dashboard/account-manage",
      label: "Account Management",
      icon: <TeamOutlined />,
      children: [
        {
          key: "/dashboard/account-manage/user",
          label: "Users",
          icon: null,
        },
        {
          key: "/dashboard/account-manage/profile",
          label: "Profiles",
          icon: null,
        },
        {
          key: "/dashboard/account-manage/waiting-list",
          label: "Waiting List",
          icon: null,
        },
        {
          key: "/dashboard/account-manage/shop-application",
          label: "Shop Applications",
          icon: null,
        },
      ],
    },
    {
      key: "/dashboard/content-manage",
      label: "Content Management",
      icon: <RocketOutlined />,
      children: [
        {
          key: "/dashboard/content-manage/shipping",
          label: "Shipping",
          icon: null,
        },
        {
          key: "/dashboard/content-manage/post",
          label: "Posts",
          icon: null,
        },
        {
          key: "/dashboard/content-manage/comment",
          label: "Comments",
          icon: null,
        },
        {
          key: "/dashboard/content-manage/reported-content",
          label: "Reported Content",
          icon: null,
        },
      ],
    },
    {
      key: "/dashboard/order-manage",
      label: "Shop Management",
      icon: <ShoppingCartOutlined />,
      children: [
        {
          key: "/dashboard/shop-manage/category",
          label: "Categories",
          icon: null,
        },
        {
          key: "/dashboard/shop-manage/discount",
          label: "Discounts",
          icon: null,
        },
        {
          key: "/dashboard/shop-manage/order",
          label: "Orders",
          icon: null,
        },
        {
          key: "/dashboard/shop-manage/products",
          label: "Products",
          icon: null,
        },
        {
          key: "/dashboard/shop-manage/shop",
          label: "Shops",
          icon: null,
        },
        {
          key: "/dashboard/shop-manage/shipping",
          label: "Shipping",
          icon: null,
        },
      ],
    },
  ],
};

const extra = {
  key: "category-extra",
  label: "EXTRA",
  icon: null,
  type: "group",
  children: [
    {
      key: "setting",
      label: "Settings",
      icon: <SettingOutlined />,
      children: [
        {
          label: "Settings",
          key: "/setting",
          icon: null,
        },
      ],
    },
    {
      label: "Admin",
      key: "admin",
      icon: <AppstoreOutlined />,
      children: [
        {
          key: "/admin/manage-list",
          label: "Admin",
          icon: null,
        },
      ],
    },
  ],
};

const CommonMenu = [manage, extra];

export default CommonMenu;
