import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "@/firebase/firebase";
import { updateUser } from "@/store/slices/userSlice";
import {
  deleteToken,
  deleteUser,
  deleteAuthUser,
  deleteRefreshToken,
  persistAuthUser,
  persistToken,
  persistRefreshToken,
  persistUser,
  readToken,
} from "@/services/localStorage.service";
import { _getValue } from "@/utils/utils";
import { logOut, userInfo } from "@/api/auth.api";

const initialState = {
  token: readToken(),
};

export const doLogin = createAsyncThunk(
  "auth/doLogin",
  async (payload, { dispatch }) => {
    const res = await signInWithEmailAndPassword(
      auth,
      payload.email,
      payload.password
    );
    const { value } = _getValue(res, "user.stsTokenManager.accessToken");
    persistToken(value);
    const uRes = await userInfo();
    console.log(uRes);
    if (uRes.role === "super_admin" || uRes.role === "admin") {
      const authUser = _getValue(res, "user").value;
      const refreshToken = _getValue(
        res,
        "user.stsTokenManager.refreshToken"
      ).value;
      persistAuthUser(authUser);
      persistRefreshToken(refreshToken);
      persistUser(uRes);
      dispatch(updateUser(uRes));
    }
    return value;
  }
);

export const doResetPassword = createAsyncThunk(
  "auth/doResetPassword",
  async (payload, { dispatch }) => {
    const res = await sendPasswordResetEmail(auth, payload.email);
    return res;
  }
);

export const setToken = createAction("auth/setToken", (token) => {
  persistToken(token);
  return {
    payload: token,
  };
});

export const doLogout = createAsyncThunk(
  "auth/doLogout",
  async (payload, { dispatch }) => {
    logOut();
    dispatch(setToken(""));
    dispatch(updateUser(null));
    deleteToken();
    deleteUser();
    deleteAuthUser();
    deleteRefreshToken();
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(setToken, (state, action) => {
      state.token = action.payload;
    });
  },
});

export default authSlice.reducer;
