import _ from "lodash";
import httpApi from "./http.api";
import { readToken, readRefreshToken } from "@/services/localStorage.service";
import { doLogout, setToken } from "@/store/slices/authSlice";
import { httpFileApi } from "./httpFile.api";

const setup = (store) => {
  httpApi.interceptors.request.use((request) => {
    const accessToken = readToken();
    request.headers["x-api-key"] = `${process.env.REACT_APP_API_AUTH_KEY}`;
    if (accessToken) {
      request.headers.Authorization = `${accessToken}`;
    }
    return request;
  });
  const { dispatch } = store;

  httpApi.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    (response) => {
      return response.data;
    },
    async (error) => {
      const originalConfig = error.config;
      const errorObject = {
        code: 500,
        message: "Server not responding",
      };

      if (error.response) {
        errorObject.code =
          _.get(error, "response.data.code") ||
          _.get(error, "response.data.key");
        errorObject.message =
          _.get(error, "response.data.title") ||
          _.get(error, "response.data.message");
      } else if (error.request) {
      } else {
        errorObject.message = "Something went wrong";
      }
      if (
        errorObject.code === "expired_token" ||
        errorObject.code === "authentication_error"
      ) {
        dispatch(doLogout());
        window.location.href = "/auth/login";
        return { data: {} };
      }

      const urls = [
        "/v1/users/get-user-info",
        "/v1/users/revoke-refresh-token",
      ];
      if (!urls.includes(originalConfig.url) && error.response) {
        if (error.response.status === 401) {
          try {
            const body = {
              refresh_token: readRefreshToken(),
            };
            const res = await httpApi.post("/v1/users/refresh-token", body);
            dispatch(setToken(res.access_token));
            return httpApi(originalConfig);
          } catch (error) {
            dispatch(doLogout());
            window.location.href = "/auth/login";
          }
        }
      }
      return Promise.reject(errorObject);
    }
  );

  httpFileApi.interceptors.request.use((request) => {
    const accessToken = readToken();
    request.headers["x-api-key"] = `${process.env.REACT_APP_API_AUTH_KEY}`;
    if (accessToken) {
      request.headers.Authorization = `${accessToken}`;
    }
    return request;
  });

  httpFileApi.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    (response) => {
      return response.data;
    },
    async (error) => {
      const errorObject = {
        code: 500,
        message: "Server not responding",
      };
      if (error.response) {
        errorObject.code =
          _.get(error, "response.data.code") ||
          _.get(error, "response.data.key");
        errorObject.message =
          _.get(error, "response.data.title") ||
          _.get(error, "response.data.message");
      } else if (error.request) {
      } else {
        errorObject.message = "Something went wrong";
      }
      if (
        errorObject.code === "expired_token" ||
        errorObject.code === "authentication_error"
      ) {
        dispatch(doLogout());
        window.location.href = "/auth/login";
        return { data: {} };
      }
      const originalConfig = error.config;
      const urls = [
        "/v1/users/get-user-info",
        "/v1/users/revoke-refresh-token",
      ];
      if (!urls.includes(originalConfig.url) && error.response) {
        if (error.response.status === 401) {
          try {
            const body = {
              refresh_token: readRefreshToken(),
            };
            const res = await httpApi.post("/v1/users/refresh-token", body);
            dispatch(setToken(res.access_token));
            return httpApi(originalConfig);
          } catch (error) {
            dispatch(doLogout());
            window.location.href = "/auth/login";
          }
        }
      }
      return Promise.reject(errorObject);
    }
  );
};

export default setup;
