import React, { useState } from "react";
import { notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BaseForm } from "@/components/common/forms/BaseForm/BaseForm";
import { nError } from "@/controllers/NotificationConfig";
import { doLogin } from "@/store/slices/authSlice";
import * as S from "./LoginForm.styles";
import * as Auth from "@/components/layout/AuthLayout/AuthLayout.styles";

export const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = BaseForm.useForm();
  const email = BaseForm.useWatch("email", form);
  const [notifi, contextHolder] = notification.useNotification();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await dispatch(doLogin(values)).unwrap();
      navigate("/dashboard/financial/earning");
    } catch (error) {
      if (error.code === "unauthorized") {
        nError(notifi, { message: "Unable to login!" });
      } else {
        nError(notifi, { message: error.message });
      }
      setLoading(false);
    }
  };

  return (
    <Auth.FormWrapper>
      <BaseForm
        form={form}
        name="login"
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
      >
        <Auth.FormTitle>Rocket Admin</Auth.FormTitle>
        <Auth.FormItem
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please enter a valid email address" },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Auth.FormInput placeholder="Email Address" />
        </Auth.FormItem>
        <Auth.FormItem
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Incorrect password" },
            { type: "string", min: 8 },
          ]}
        >
          <Auth.FormInputPassword placeholder="Password" maxLength={23} />
        </Auth.FormItem>
        {/* <Auth.ActionsWrapper>
          <Link to="/auth/forgot-password" state={{ email }}>
            <S.ForgotPasswordText>Forgot Password?</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper> */}
        <BaseForm.Item noStyle>
          <Auth.SubmitButton
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Sign In
          </Auth.SubmitButton>
        </BaseForm.Item>
        {contextHolder}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
